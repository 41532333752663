import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { PluginsSdk } from '../pluginsdk'

const Plug = () => {
	return (
		<L10nProvider>
			<PluginsSdk />
		</L10nProvider>
	)
}

export default Plug;